import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    redirect: "/sellerManagement",
    component: () => import("../views/AdminRoute.vue"),
    children: [
      // {
      // 	path: 'dashboard',
      // 	name: 'Dashboard',
      // 	component: () => import('../views/Dashboard/DashboardRoute.vue')
      // },
      // Add this inside the children array of the root path '/' route, alongside other management routes

      {
        path: "campaignManagement",
        name: "CampaignManagement",
        component: () =>
          import("../views/CampaignManagement/CampaignManagementRoute.vue"),
        redirect: { name: "ActiveCampaigns" },
        children: [
          {
            path: "",
            name: "ActiveCampaigns",
            component: () =>
              import("../views/CampaignManagement/ActiveCampaigns.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "",
            name: "DeletedCampaigns",
            component: () =>
              import("../views/CampaignManagement/DeletedCampaigns.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "campaign/:id",
            name: "CampaignDetails",
            component: () =>
              import("../views/CampaignManagement/CampaignDetails.vue"),
            meta: {
              subComponent: false,
            },
          },
          // {
          //     path: 'scheduled',
          //     name: 'ScheduledCampaigns',
          //     component: () => import('../views/CampaignManagement/ScheduledCampaigns.vue'),
          //     meta: {
          //         subComponent: true
          //     }
          // },
          // {
          //     path: 'completed',
          //     name: 'CompletedCampaigns',
          //     component: () => import('../views/CampaignManagement/CompletedCampaigns.vue'),
          //     meta: {
          //         subComponent: true
          //     }
          // },
          // {
          //     path: 'archived',
          //     name: 'ArchivedCampaigns',
          //     component: () => import('../views/CampaignManagement/ArchivedCampaigns.vue'),
          //     meta: {
          //         subComponent: true
          //     }
          // },
        ],
      },
      {
        path: "sellerManagement",
        component: () =>
          import("../views/SellerManagement/SellerManagementRoute.vue"),
        children: [
          {
            path: "",
            name: "AllSellers",
            component: () => import("../views/SellerManagement/AllUsers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "active",
            name: "ActiveSellers",
            component: () =>
              import("../views/SellerManagement/ActiveSellers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "approval",
            name: "ApprovalPending",
            component: () =>
              import("../views/SellerManagement/ApprovalPending.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "rejected",
            name: "RejectedSellers",
            component: () =>
              import("../views/SellerManagement/RejectedSellers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "blocked",
            name: "BlockedSellers",
            component: () =>
              import("../views/SellerManagement/BlockedSellers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "archived",
            name: "ArchivedSellers",
            component: () =>
              import("../views/SellerManagement/ArchivedSellers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "sellerDetails/:sellerId",
            name: "SellerDetails",
            component: () =>
              import("@/views/SellerManagement/SellerDetails.vue"),
          },
          {
            path: "requests",
            name: "Requests",
            component: () => import("@/views/SellerManagement/Requests.vue"),
            children: [
              {
                path: "edit",
                name: "EditRequest",
                component: () =>
                  import("@/views/SellerManagement/EditRequests.vue"),
              },
              {
                path: "help",
                name: "HelpRequest",
                component: () =>
                  import("@/views/SellerManagement/HelpRequests.vue"),
              },
              {
                path: "accountDelete",
                name: "AccountDeleteRequest",
                component: () =>
                  import("@/views/SellerManagement/AccountDeleteRequest.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "customerManagement",
        component: () =>
          import("../views/CustomerManagement/CustomerManagementRoute.vue"),
        children: [
          {
            path: "",
            name: "ActiveCustomers",
            component: () =>
              import("../views/CustomerManagement/ActiveCustomers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "blockedCustomers",
            name: "BlockedCustomers",
            component: () =>
              import("../views/CustomerManagement/BlockedCustomers.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "customerDetails/:customerId",
            name: "CustomerDetails",
            component: () =>
              import("../views/CustomerManagement/CustomerDetails.vue"),
          },
        ],
      },
      {
        path: "inventoryManagement",
        component: () =>
          import("../views/InventoryManagement/InventoryManagementRoute.vue"),
        children: [
          {
            name: "LiveInventory",
            path: "",
            component: () =>
              import("../views/InventoryManagement/LiveInventory.vue"),
            meta: {
              subComponent: true,
              fabritoStatus: "ACTIVE",
            },
          },
          {
            name: "ApprovePendingInventory",
            path: "pendingToApprove",
            component: () =>
              import(
                "../views/InventoryManagement/ApprovePendingInventory.vue"
              ),
            meta: {
              subComponent: true,
              fabritoStatus: "PENDING",
            },
          },
          {
            name: "PublishPendingInventory",
            path: "pendingToPublish",
            component: () =>
              import(
                "../views/InventoryManagement/PublishPendingInventory.vue"
              ),
            meta: {
              subComponent: true,
              fabritoStatus: "APPROVED",
            },
          },
          {
            name: "RejectedInventory",
            path: "rejected",
            component: () =>
              import("../views/InventoryManagement/RejectedInventory.vue"),
            meta: {
              subComponent: true,
              fabritoStatus: "REJECTED",
            },
          },
          {
            name: "ArchivedInventory",
            path: "archived",
            component: () =>
              import("../views/InventoryManagement/ArchivedInventory.vue"),
            meta: {
              subComponent: true,
              fabritoStatus: "ARCHIVED",
            },
          },
          {
            name: "FabricDetails",
            path: "fabricDetails/:id",
            component: () => import("@/components/FabricDetails.vue"),
            meta: {
              subComponent: false,
            },
          },
          {
            name: "FabricGroups",
            path: "fabricGroups",
            component: () =>
              import("../views/InventoryManagement/FabricGroups.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            name: "Keywords",
            path: "/keywords",
            component: () =>
              import("../views/InventoryManagement/Keywords.vue"),
            meta: {
              subComponent: true,
              removeFliterComponent: true,
            },
          },
          {
            name: "FabricGroupDetails",
            path: "fabricGroupDetails/:groupId",
            component: () =>
              import(
                "../components/InventoryManagement/FabricGroupDetails.vue"
              ),
            meta: {
              subComponent: false,
            },
          },
          {
            path: "SellerInventory/:sellerId",
            component: () =>
              import("../views/SellerInventory/SellerInventoryRoute.vue"),
            children: [
              {
                name: "SellerLiveInventory",
                path: "",
                component: () =>
                  import("../views/SellerInventory/LiveInventory.vue"),
                meta: {
                  childComponent: true,
                  fabritoStatus: "ACTIVE",
                },
              },
              {
                name: "SellerApprovePendingInventory",
                path: "SellerPendingToApprove",
                component: () =>
                  import(
                    "../views/SellerInventory/ApprovePendingInventory.vue"
                  ),
                meta: {
                  childComponent: true,
                  fabritoStatus: "PENDING",
                },
              },
              {
                name: "SellerPublishPendingInventory",
                path: "SellerPendingToPublish",
                component: () =>
                  import(
                    "../views/SellerInventory/PublishPendingInventory.vue"
                  ),
                meta: {
                  childComponent: true,
                  fabritoStatus: "APPROVED",
                },
              },
              {
                name: "SellerRejectedInventory",
                path: "SellerRejected",
                component: () =>
                  import("../views/SellerInventory/RejectedInventory.vue"),
                meta: {
                  childComponent: true,
                  fabritoStatus: "REJECTED",
                },
              },
              {
                name: "SellerArchivedInventory",
                path: "SellerArchived",
                component: () =>
                  import("../views/SellerInventory/ArchivedInventory.vue"),
                meta: {
                  childComponent: true,
                  fabritoStatus: "ARCHIVED",
                },
              },
            ],
          },
        ],
      },

      {
        path: "blogManagement",
        name: "BlogManagement",
        component: () =>
          import("../views/BlogManagement/BlogManagementRoute.vue"),
        redirect: { name: "ActiveBlogs" },
        children: [
          {
            path: "",
            name: "InActiveBlogs",
            component: () => import("../views/BlogManagement/InActiveBlogs.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "",
            name: "ActiveBlogs",
            component: () => import("../views/BlogManagement/ActiveBlogs.vue"),
            meta: {
              subComponent: true,
            },
          }, 
          {
            path: "",
            name: "DeletedBlogs",
            component: () => import("../views/BlogManagement/DeletedBlogs.vue"),
            meta: {
              subComponent: true,
            },
          },
        ],
      },

      {
        path: "orderManagement",
        component: () =>
          import("../views/OrderManagement/OrderManagementRoute.vue"),
        children: [
          {
            path: "",
            name: "PendingOrder",
            component: () => import("../views/OrderManagement/Pending.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "active",
            name: "ActiveOrder",
            component: () => import("../views/OrderManagement/Active.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "completed",
            name: "CompletedOrder",
            component: () => import("../views/OrderManagement/Completed.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "cancelled",
            name: "CancelledOrder",
            component: () => import("../views/OrderManagement/Cancelled.vue"),
            meta: {
              subComponent: true,
            },
          },
          {
            path: "orderDetails/:orderID",
            name: "OrderDetails",
            component: () => import("@/views/OrderManagement/OrderDetails.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/sellerManagement",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const userRole = Cookies.get("role"); // Get role from cookies
  // console.log("User Role from Cookie:", userRole);
  if (userRole === "seo") {
    const allowedRoutes = ["/blogManagement", "/login"]; // SEO users can access only these routes

    if (!allowedRoutes.includes(to.path) && !to.path.startsWith("/blogManagement")) {
      return next("/blogManagement"); // Redirect to BlogManagement if trying to access anything else
    }
  }

  next(); // Allow access if the role is permitted
});


export default router;
